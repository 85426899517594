/**
 * The Gatsby Browser API lets you respond to Gatsby-specific events within the browser, and wrap your page components in additional global components.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react'
import { getSessionPassword } from './src/components/passwordProtect/utils'
import PasswordProtect from './src/components/passwordProtect/PasswordProtect'

export const wrapPageElement = () => {
	const password = process.env.PASSWORD_PROTECT

	// password protection disabled if PROD
	if (process.env.PRODUCTION === true || process.env.PRODUCTION === 'true') {
		return
	}

	// check password
	const passwordCandidate = getSessionPassword()
	if (passwordCandidate === password) {
		return
	}

	// ask for password
	return <PasswordProtect />
}
