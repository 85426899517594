import { FC, ReactElement, RefObject } from 'react'
import { thirdStepSubSteps } from '@templates/requests/create_tn'
import { AddressInfo, Coordinate } from '@components/ui/map/mapForm.interfaces'
import { Request } from 'typings/shared'
import { REQUEST_TN_DETAILS_STEPS_NAME } from '@services/constants'

type RequestNameType = {
	shedGazeboSolarium: string
	constructionStandardsFloodRisk: string
	miscellaneousEquipment: string
	swimmingPoolSpa: string
	crossingGuardAndSchool: string
	renovationConstructionBuilding: string
	branchPickup: string
	evaluateTree: string
	authorisedBusinesses: string
	flooding: string
	speedAndSignaling: string
	municipalTaxes: string
	othersRequests: string
	landscapingAndMaintenance: string
	materialsOrWasteNotCollected: string
	binLostStoleIncorrectlyReplace: string
	pothole: string
	pandemicEpidemic: string
	newBinAddOrChange: string
	brokenBin: string
	landRegistryModification: string
	visualPollution: string
	curbAndSidewalk: string
	zoning: string
	floorLampAndLighting: string
	obligationsAndStandards: string
	panelsAndSignaling: string
	snowClearingProblem: string
	trafficLightAndPedestrianLight: string
	treeDonation: string
}

export type RequestFormPDFProps = {
	request?: Request
	closeLink?: string
	loading?: boolean
	hasFileRequiredError: boolean
	files?: Set<File>
	inputFiles: RequestInputFiles[]
	hasFileError: boolean
	inputs: RequestInputs[]
	subSteps: thirdStepSubSteps | undefined
	errors: string[]
	steps?: StepType
	toPrint?: boolean
	isSummaryStep?: boolean
	filesInputs: RequestInputFiles[]
	onFixError: React.Dispatch<React.SetStateAction<string[]>>
	formPosition?: number
	onSetHasFileRequiredError: (error: boolean) => void
	onSetFiles?: (file: Set<File>) => void
	onSetHasFileError: (error: boolean) => void
	onSetInputs: (inputs: RequestInputs[]) => void
	onSetInputFiles: <U = Set<File>>(input: RequestInputFiles<U>[]) => void
	onSetSubSteps: React.Dispatch<
		React.SetStateAction<thirdStepSubSteps | undefined>
	>
	setCurrentStep?: React.Dispatch<React.SetStateAction<number>>
	onCustomError?: React.Dispatch<React.SetStateAction<boolean>>
	onSetFilesInputs?: (inputs: RequestInputFiles[]) => void
	onProcessingData?: () => void
	setDisableNextButton?: React.Dispatch<React.SetStateAction<boolean>>
	setRequiredFieldAttributes?: React.Dispatch<React.SetStateAction<string[][]>>
	setRequiredFileAttributes?: React.Dispatch<React.SetStateAction<string[]>>
}

export type Components = {
	[componentKey: string]: FC<RequestFormProps>
}

export type Component = FC<RequestFormProps> | FC<{}>

export type RequestInputs = {
	name: string
	value: string
	valueKey?: string
	label: string
	labelKey: string
	required: boolean
	disabled?: boolean
	temporaryName?: string
	ref?: RefObject<
		| HTMLInputElement
		| HTMLSelectElement
		| HTMLTextAreaElement
		| HTMLImageElement
	> | null
	subStep?: string
	description?: string
	extraParams?: {
		type?: string
		max?: string
		min?: string
		customErrorText?: string
	}
}

export type RequestInputFiles<T = Set<File>> = {
	name: string
	label: string
	description: string
	files: T
	labelKey: string
	required: boolean
	disabled?: boolean
	namingValidation?: string
	numberFilesRequired?: number
	numberMaxFiles?: number
	category?: string
	separateFiles?: { [key: string]: Set<File> }
	subStep?: string
	shouldValidateIn?: REQUEST_TN_DETAILS_STEPS_NAME
}

export type RequestImgUrl = {
	id: string
	name: string
	url: string
	isPDF: boolean
}

export type RadioList = {
	value: string
	label: string
	key: string
	extraText?: string | string[]
	details?: ReactElement
	updateDetails?: boolean
	toolTip?: boolean
	toolTipLabel?: string
}

export type TooltipProp = {
	showInfo?: boolean
	infoLabel?: string
	children?: ReactElement
	changeOnShow?: boolean
	duration?: number
	toolTipMedia?: string
	isModal?: boolean
}

export type inputTablesFields = {
	headers: string[]
	rows: {
		label: string
		selected: boolean
	}[][]
}

export type SelectList = RadioList

export interface CheckboxList extends RadioList {
	checked: boolean
	isOther?: boolean
}

export type SelectImageList = SelectList & {
	img: string
	description?: string
}

export type Article = {
	id: string
	title: string
	articlenumber: string
	description: string
	language: string
}

export type StepType = {
	start: number
	summary: number
	applicant: number
	form: number
	confirmation: number
}

export type RequestFormProps = {
	inputs: RequestInputs[]
	errors: string[]
	onInputsChange: (inputs: RequestInputs[]) => void
	onFixError: (errors: string[]) => void
	filledForm?: boolean
}

export type ButtonWithTooltipProps = {
	type: 'edit' | 'cancel'
	onclick: (e?: any) => void
}

const RequestNames: RequestNameType = {
	shedGazeboSolarium: 'shedGazeboSolarium',
	constructionStandardsFloodRisk: 'constructionStandardsFloodRisk',
	miscellaneousEquipment: 'miscellaneousEquipment',
	swimmingPoolSpa: 'swimmingPoolSpa',
	crossingGuardAndSchool: 'crossingGuardAndSchool',
	renovationConstructionBuilding: 'renovationConstructionBuilding',
	branchPickup: 'branchPickup',
	evaluateTree: 'evaluateTree',
	authorisedBusinesses: 'authorisedBusinesses',
	flooding: 'flooding',
	speedAndSignaling: 'speedAndSignaling',
	municipalTaxes: 'municipalTaxes',
	landscapingAndMaintenance: 'landscapingAndMaintenance',
	materialsOrWasteNotCollected: 'materialsOrWasteNotCollected',
	binLostStoleIncorrectlyReplace: 'binLostStoleIncorrectlyReplace',
	pothole: 'pothole',
	pandemicEpidemic: 'pandemicEpidemic',
	newBinAddOrChange: 'newBinAddOrChange',
	brokenBin: 'brokenBin',
	landRegistryModification: 'landRegistryModification',
	visualPollution: 'visualPollutionAndDirtiness',
	curbAndSidewalk: 'curbAndSidewalk',
	zoning: 'zoning',
	floorLampAndLighting: 'floorLampAndLighting',
	obligationsAndStandards: 'obligationsAndStandards',
	panelsAndSignaling: 'panelsAndSignaling',
	othersRequests: 'othersRequests',
	snowClearingProblem: 'snowClearingProblem',
	trafficLightAndPedestrianLight: 'trafficLightAndPedestrianLight',
	treeDonation: 'treeDonation'
}

export const TNRequestNames = {
	clothDiapers: 'clothDiapers',
	rainwaterBarrel: 'rainwaterBarrel',
	replacementOilHeatingSystem: 'replacementOilHeatingSystem',
	heritageBuildingsRevitalizationProgram:
		'heritageBuildingsRevitalizationProgram',
	crackedHouses: 'crackedHouses',
	treePlanting: 'treePlanting',
	temporaryPesticideApplicationPermitForIndividuals:
		'temporaryPesticideApplicationPermitForIndividuals',
	claimNotice: 'claimNotice',
	publicAuctions: 'publicAuctions',
	buildingTransactionRequest: 'buildingTransactionRequest',
	oilHeatingDeclaration: 'oilHeatingDeclaration',
	historicalArchive: 'historicalArchive',
	annualRegistrationCertificateForContractors:
		'annualRegistrationCertificateForContractors',
	selfReliefWaterCounter: 'selfReliefWaterCounter',
	hydraulicAndPressureTests: 'hydraulicAndPressureTests',
	fireHydrantsTempAqueductNetwork: 'fireHydrantsTempAqueductNetwork',
	fireHydrantsTankFilling: 'fireHydrantsTankFilling',
	annualPermitAndTagForAnimals: 'annualPermitAndTagForAnimals',
	municipalEvaluation: 'municipalEvaluation',
	fireplaceDeclaration: 'fireplaceDeclaration',
	parkingPermit: 'parkingPermit',
	permitOccupationOfPublicHighway: 'permitOccupationOfPublicHighway',
	sustainableHygieneProducts: 'sustainableHygieneProducts',
	carSharing: 'carSharing',
	bikeSharing: 'bikeSharing',
	fireplaceGrant: 'fireplaceGrant'
}

const UpdateRequestInputsArray = (
	inputsArr: RequestInputs[],
	currentInput: RequestInputs
): RequestInputs[] => {
	const newInputsArr: RequestInputs[] = inputsArr
	const index: number = newInputsArr.findIndex(
		(input) => input.name === currentInput.name
	)

	if (index < 0) {
		newInputsArr.push(currentInput)

		return newInputsArr
	}

	newInputsArr[index].value = currentInput.value

	return newInputsArr
}

const RequestType = {
	theme: 'requestThemes',
	subTheme: 'requestSubThemes'
}

const AddressInputName: string = 'addressMapSearch'

export const Steps: StepType = {
	start: 1,
	applicant: 2,
	form: 3,
	summary: 4,
	confirmation: 5
}

export const OthersRequestsId: { name: string; key: string } = {
	name: 'other-subject',
	key: '00000000-0000-0000-0000-000000000000'
}

export { RequestNames, RequestType, AddressInputName, UpdateRequestInputsArray }

export type PlantedTree = {
	species: string
	diameter: string
	cost: string
	location: string
	height: string
	planting_date: string
	type?: string
	openModal?: boolean
}

export type PesticideCertificates = {
	holder_name: string
	certificat_number: string
	category: string
	issue_date: string
	expiration_date: string
}

export type VehicleInformation = {
	brand?: string
	model?: string
	year?: string
	color?: string
	registration_number?: string
	business_identified?: boolean
	plate_number?: string
	tank_volume?: string
	frequency_of_use?: string
}

export type TankTruck = {
	plate_number: string
	tank_volume: string
	frequency_of_use: string
}

export type AnimalInformation = {
	display_information?: boolean
	type: string
	name: string
	gender?: string
	breed?: string
	cross_breed?: boolean
	color?: string
	distinctive_sign?: string
	birth_date?: string
	weight?: string
	weight_unit?: string
	birth_place?: string
	is_micro_chipped?: string
	micro_chipped_number?: string
	is_sterilized?: string
	sterilized_date?: string
	is_rabies_vaccinated?: string
	rabies_vaccinated_date?: string
	service_animal?: string
	permit_term?: string
	covered_exception?: string
	// ======== renew permit requests ================= //
	is_renewing?: string
	tag_number?: string
	renewal_duration?: string
	tag_number_unknown?: string
	non_renewal_reason?: string
}
export type AnimalInformationFile = {
	proof_of_microchipped: File[]
	proof_of_microchipped_contract: File[]
	proof_of_rabies_vaccination: File[]
	proof_of_sterilization: File[]
	proof_of_sterilization_exemption: File[]
	proof_of_need_of_service_animal: File[]
}

export type AnimalRequiredFileAttributes = {
	proof_of_microchipped: boolean
	proof_of_microchipped_contract: boolean
	proof_of_rabies_vaccination: boolean
	proof_of_sterilization: boolean
	proof_of_sterilization_exemption: boolean
	proof_of_need_of_service_animal: boolean
}

export enum AnimalType {
	Dog = '104820000',
	Cat = '104820001'
}

export type ParkingPermitInformation = {
	display_information: boolean
	//
	vehicle_plate_number: string // Numéro de plaque d'immatriculation
	vehicle_brand: string // Marque du véhicule
	vehicle_model: string // Modèle du véhicule
	vehicle_year: string // Année du véhicule

	// is_vehicle_owner?: string
	is_owner_same_as_vignette?: string
	//
	renewal_sticker_number?: string // Numéro de la vignette
	renewal_sticker_owner_name?: string // Nom du propriétaire de la vignette

	reason_for_replacement?: string
	reason_for_replacement_other?: string
	vehicle_owner?: string // Êtes vous propriétaire du véhicule
	know_vignette_number?: string
}

export type ParkingPermitFile = {
	applicant_proof_of_recidence?: File[]
	vehicle_registration?: File[]
	proof_of_insurance?: File[]
}

export type AdministrativeRiviewInfo = {
	date: string
	start_time: string
	end_time: string
	is_24_7: boolean
	is_weekend: boolean
	is_from_config?: boolean
	occpation_value: string
}
