import axios, { AxiosError } from 'axios'
import apiClient from '@services/api.client'
import {
	AxiosResponse,
	ITeamMembersRepository,
	IActivityLogRepository,
	RequestTypeParam,
	IDistrictRepository,
	IGeoLocationRepository,
	ICoodinate
} from '@services/types'
import { acquireAccessToken } from '@utils/authConfig'
import {
	IActivityLogItemDTO,
	IDistrictDTO,
	IDistrictResponseDTO,
	ILocationDTO,
	ILocationResponseDTO,
	ITeamMemberDTO
} from '@services/dtos/_miscellaneous.dto'
import {
	StaticApiAddressProvidersEnum,
	StaticApiSearchTypeEnum,
	TIMELINE_PARAMS_CONFIGS
} from '@services/constants'

export class BaseRepository {
	protected resource: string

	constructor() {
		this.resource = ''
	}

	protected stringify(params: Record<string, string | number>): string {
		return JSON.stringify(params)
	}
}

export class TeamMembersRepository
	extends BaseRepository
	implements ITeamMembersRepository
{
	constructor() {
		super()
		this.resource = 'elected_teams'
	}

	public async getElectectedTeamMembersAsync(): Promise<ITeamMemberDTO[]> {
		try {
			const response: AxiosResponse<ITeamMemberDTO[]> = await apiClient.get(
				'',
				{
					params: {
						resource: this.resource
					},
					headers: {
						token: await acquireAccessToken()
					}
				}
			)

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					'Error while fetching the elected team members: ' + error.message
				)
			}

			throw error
		}
	}
}

export class DistrictRepository
	extends BaseRepository
	implements IDistrictRepository
{
	constructor() {
		super()
	}

	public async getDistrictsAsync(): Promise<IDistrictDTO[]> {
		try {
			const response: AxiosResponse<IDistrictResponseDTO> = await // axios.get(
			// 	'https://geoapi.laval.ca/thinkwhere/api/features/placetypes/electoral_district?searchType=geocoding&size=30'
			// )

			axios.get(`/api/addresses`, {
				params: {
					serviceProvider: StaticApiAddressProvidersEnum.DISTRICTS,
					search: {
						size: 30
					},
					searchType: StaticApiSearchTypeEnum.district
				},
				headers: {
					token: await acquireAccessToken()
				}
			})
			return response.data?.features
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching the districts: ' + error.message)
			}
			throw error
		}
	}
}

export class ActivityLogRepository
	extends BaseRepository
	implements IActivityLogRepository
{
	constructor() {
		super()
		this.resource = 'timeline'
	}

	public async searchActivitiesByResourceIdAsync(
		requestTypeParam: RequestTypeParam,
		resourceId: string | number
	): Promise<IActivityLogItemDTO[]> {
		try {
			let params = {
				[requestTypeParam]: resourceId,
				include_attachments_metadata: `${
					requestTypeParam === 'customer_id' ? false : true
				}`
			}

			if (requestTypeParam === 'customer_id') {
				params = {
					...params,
					last_n_days: TIMELINE_PARAMS_CONFIGS.nbDays,
					top: TIMELINE_PARAMS_CONFIGS.nbTopResults
				}
			}

			const response: AxiosResponse<IActivityLogItemDTO[]> =
				await apiClient.get('', {
					params: {
						resource: this.resource,
						params: this.stringify(params)
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error(
					'Error while searching activities for resourceId ( ' +
						resourceId +
						' ) ' +
						error.message
				)
			}

			throw error
		}
	}
}

export class GeoLocationRepository
	extends BaseRepository
	implements IGeoLocationRepository
{
	constructor() {
		super()
	}

	public async getLocationByCoordinateAsync(coordinates?: ICoodinate): Promise<ILocationDTO[]> {
		try {

			const coordinate = {
				latitude: coordinates?.latitude,
				longitude: coordinates?.longitude
			}

			const response: AxiosResponse<ILocationResponseDTO> = await // axios.get(
			// 	'https://geoapi.laval.ca/thinkwhere/api/features/placetypes/electoral_district?searchType=geocoding&size=30'
			// )
			
			axios.get(`/api/addresses`, {
				params: {
					serviceProvider: StaticApiAddressProvidersEnum.LOCATION,
					search: coordinate,
					searchType: StaticApiSearchTypeEnum.coordinate
				},
				headers: {
					token: await acquireAccessToken()
				}
			})
			return response.data?.features
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching the location: ' + error.message)
			}
			throw error
		}
	}
	public async getLocationByAddresssAsync(address?: string): Promise<ILocationDTO[]> {
		try {

			const response: AxiosResponse<ILocationResponseDTO> = await // axios.get(
			// 	'https://geoapi.laval.ca/thinkwhere/api/features/placetypes/electoral_district?searchType=geocoding&size=30'
			// )
			
			axios.get(`/api/addresses`, {
				params: {
					serviceProvider: StaticApiAddressProvidersEnum.LOCATION,
					search: address,
					searchType: StaticApiSearchTypeEnum.address
				},
				headers: {
					token: await acquireAccessToken()
				}
			})
			return response.data?.features
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching the location: ' + error.message)
			}
			throw error
		}
	}
}
