import React, { Dispatch, FC, SetStateAction, useContext } from 'react'
import { joinClasses } from '@utils/styles'
import { Icon } from '@components/ui/Icon'
import { formatStrapiText, timeDisplay } from '@utils/methods'
import OutsideClickHandler from 'react-outside-click-handler'
import { dotMenuIcon, paperGreyClipIcon } from '@images/icons'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { ITimeline } from '@services/types'
import { CustomDate } from '@services/models/shared.model'
import Text from '@components/ui/text'
import { CATEGORIES } from '@services/constants'
import * as pageUtils from '@components/ui/historyCard/__index.utils'
import { STATUS_ID, TimelineLabel } from '@components/homepage/__index.utils'

const handleDropdown = (id) => {
	const el = document.getElementById(id) as HTMLDivElement

	el.style.display = 'inline-block'
}

const turnOffDropdown = (id) => {
	const el = document.getElementById(id) as HTMLDivElement
	el.style.display = 'none'
}

export enum VALUE_CHANGE {
	CANCELATION = 'incident_cancelled_reason',
	CREATION = 'Création',
	PROCESSING = 'Assignation',
	ENDED = 'Terminé'
}

export enum ORIGIN {
	CITIZEN = 'citizen',
	CITY = 'city'
}

export enum ACTIVITY_TYPE {
	EMAIL = 'email',
	PORTAL_COMMENTS = 'portal_comment',
	INCIDENT_CREATED = 'incident_created',
	APPOINTMENT = 'appointment',
	PHONE_CALL = 'phone_call'
}

type timelineBlockProps = {
	// history: Array<TimelineType>
	history?: ITimeline[] | undefined
	classWrapper?: string
	setModalCommunication: Dispatch<SetStateAction<ITimeline | undefined>>
	setOrigin: React.Dispatch<React.SetStateAction<ORIGIN | undefined>>
	onOpenModal: Dispatch<SetStateAction<boolean>>
}

export const getElectedName = (str) => {
	return str ? JSON.parse(str).elected_team_name + '.' : ''
}

const TimelineBlock: FC<timelineBlockProps> = ({
	history,
	classWrapper,
	setModalCommunication,
	setOrigin,
	onOpenModal
}) => {
	const { pageData, language } = useContext(AppStateContext)

	const getRequestTitleByOverview = (overview, type) => {
		if (type === ACTIVITY_TYPE.PORTAL_COMMENTS)
			return pageData.requests.find((req) => req.requestId === overview)?.title

		return (
			pageData.requests.find((req) => req.name == overview)?.title || overview
		)
	}

	const getRequestTitleByType = (timeline: ITimeline) => {
		if (
			timeline?.valueChange?.type == VALUE_CHANGE.CANCELATION ||
			timeline?.valueChange?.value == STATUS_ID.CREATION
		) {
			timeline.from = ORIGIN.CITIZEN
		}

		if (timeline?.valueChange?.type == 'status')
			return (
				pageData.assets &&
				pageData.assets[TimelineLabel[timeline.valueChange?.value]]
			)
	}

	const getTimelineTitle = (timeline: ITimeline): any => {
		if (timeline.valueChange)
			return <Text content={getRequestTitleByType(timeline)} />
		if (timeline.activity?.type == ACTIVITY_TYPE.APPOINTMENT) {
			return pageData.assets.myRequestPage_a_summary_of_the_metting_was_added
		}
		if (
			timeline.activity?.type == ACTIVITY_TYPE.PHONE_CALL &&
			timeline.from == ORIGIN.CITY
		) {
			return pageData.assets.myRequestPage_phone_call_added_by_laval
		}
		if (
			timeline.activity?.type == ACTIVITY_TYPE.PHONE_CALL &&
			timeline.from == ORIGIN.CITIZEN
		) {
			return pageData.assets
				.myRequestPage_you_communicated_with_us_through_phone
		} else if (
			timeline.activity?.description?.includes(
				'Elle a maintenant le statut « fermé ».'
			)
		)
			return (
				<Text
					content={
						pageData?.assets
							?.label_the_status_of_the_request_has_been_changed_to_closed
					}
				/>
			)
		else if (
			timeline.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS &&
			timeline.from == ORIGIN.CITIZEN
		)
			return formatStrapiText(
				pageData?.assets
					?.myRequest_you_have_communicated_with_us_through_mon_dossier
			)
		else if (
			timeline.activity?.type == ACTIVITY_TYPE.EMAIL &&
			timeline.activity?.overview?.includes(
				'Confirmation de création d’une requête'
			)
		) {
			timeline.from = ORIGIN.CITIZEN
			return pageData.assets?.label_you_have_submitted_the_request
		} else if (
			timeline.activity?.overview?.includes(
				'Votre demande a été prise en charge'
			)
		) {
			return pageData.assets?.myRequest_page_the_request_was_handled
		} else if (timeline.activity?.category === CATEGORIES.CREATE_CONSENT) {
			timeline.from = ORIGIN.CITIZEN
			return (
				pageData.assets.You_have_added_a_consent_to_the_elected +
				getElectedName(timeline.activity?.metadata)
			)
		} else if (timeline.activity?.category === CATEGORIES.REVOKE_CONSENT) {
			timeline.from = ORIGIN.CITIZEN
			return (
				pageData.assets.You_have_revoked_the_consent_of_the_elected +
				getElectedName(timeline.activity?.metadata)
			)
		} else if (timeline.activity?.category === CATEGORIES.CANCEL_REQUEST)
			return formatStrapiText(
				pageData?.assets?.you_have_asked_for_the_motion_to_be_rescinded
			)
		else if (
			(timeline.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS &&
				timeline.from == ORIGIN.CITY) ||
			timeline.activity?.category == CATEGORIES.EMAIL
		) {
			if (
				timeline.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS &&
				!timeline.activity?.overview?.includes(
					'Votre demande a été prise en charge'
				)
			) {
				return pageData?.assets
					?.myRequest_a_message_was_added_by_la_ville_de_laval
			}

			return `${pageData.assets?.myRequest_the_message} ${
				timeline.activity.overview || ''
			} ${pageData.assets?.myRequest_page_was_added}`
		} else if (
			timeline.from == ORIGIN.CITY &&
			timeline.activity?.type == ACTIVITY_TYPE.EMAIL
		)
			return pageData?.assets
				?.myRequest_a_message_was_added_by_la_ville_de_laval
		else
			return getRequestTitleByOverview(
				timeline.activity?.overview,
				timeline.activity?.type
			)
	}

	const shouldTimelineCardDisplay = (timeline: ITimeline): boolean => {
		if (timeline?.valueChange) return true

		if (timeline?.activity) {
			if (timeline.activity?.type == ACTIVITY_TYPE.INCIDENT_CREATED)
				return false

			if (timeline?.from == ORIGIN.CITY && !timeline.activity?.category) {
				if (
					timeline.activity?.type == ACTIVITY_TYPE.EMAIL &&
					timeline.activity?.overview?.includes(
						'Ajout d’une information à votre requête'
					)
				)
					return false

				return true
			}

			return true
		}

		return false
	}

	const getTextWithoutLineBreaks = (description: string): string => {
		return description?.replace(/(\r\n|\n|\r)/gm, ' ')
	}

	const shouldDisplayShortMessage = (timeline: ITimeline): boolean => {
		if (timeline?.activity) {
			if (
				timeline.activity.overview?.includes(
					'Votre demande a été prise en charge'
				)
			)
				return true
			else if (
				timeline.activity.overview?.toLowerCase().includes('nouveau message')
			)
				return true
			else if (
				timeline.activity?.description?.includes(
					'Elle a maintenant le statut « fermé ».'
				)
			)
				return true

			if (timeline.activity?.type == ACTIVITY_TYPE.APPOINTMENT) {
				return true
			}
			if (timeline.activity?.type == ACTIVITY_TYPE.PORTAL_COMMENTS) return true
			if (timeline.activity?.type == ACTIVITY_TYPE.PHONE_CALL) return true
			if (
				timeline.activity?.type == ACTIVITY_TYPE.EMAIL &&
				timeline.from == ORIGIN.CITY
			)
				return true
		}

		return false
	}

	return (
		<>
			{!!history &&
				history.length > 0 &&
				history
					.filter((timeline) => shouldTimelineCardDisplay(timeline))
					.filter((timeline: ITimeline, index: number, array: ITimeline[]) => {
						if (
							index >= 0 &&
							timeline.activity?.description?.includes(
								'Nous vous avons transmis un nouveau message concernant votre requête'
							) &&
							array[index + 1].activity?.description?.includes(
								'Il est possible que nous communiquions avec vous'
							)
						) {
							return false
						}
						return true
					})
					.map((timeline, index: number) => (
						<div key={index} className={pageUtils.classes.timelineBlock}>
							<div
								className={joinClasses([
									pageUtils.classes.marquer,
									timeline.from === ORIGIN.CITY
										? pageUtils.classes.notif
										: pageUtils.classes.notifCityzen
								])}
							></div>
							<div
								className={joinClasses([
									pageUtils.classes.timelineContent,
									'card-content'
								])}
							>
								{(!!timeline.activity || !!timeline.valueChange) &&
									!shouldDisplayShortMessage(timeline) && (
										<div className={pageUtils.classes.dropdown}>
											<button
												className={joinClasses([
													pageUtils.classes.dropbtn,
													!timeline.activity?.description
														? pageUtils.classes.dropbtnDisable
														: ''
												])}
												onClick={() =>
													handleDropdown(`drp${index}-${classWrapper}`)
												}
											>
												<Icon
													src={dotMenuIcon}
													className={pageUtils.classes.dropbtnImg}
												/>
											</button>
											{!!timeline.activity?.description && (
												<OutsideClickHandler
													onOutsideClick={() =>
														turnOffDropdown(`drp${index}-${classWrapper}`)
													}
												>
													<div
														id={`drp${index}-${classWrapper}`}
														className={pageUtils.classes.dropdownContent}
													>
														<ul className={pageUtils.classes.dropdownMenu}>
															<li>
																<a
																	onClick={() => {
																		setModalCommunication(timeline)
																		setOrigin(timeline.from as ORIGIN)
																		onOpenModal(true)
																	}}
																	className={pageUtils.classes.dropdownLink}
																>
																	{
																		pageData?.assets
																			?.myRequest_show_the_communication
																	}
																</a>
															</li>
														</ul>
													</div>
												</OutsideClickHandler>
											)}
										</div>
									)}

								<span className={pageUtils.classes.historyCardTitle}>
									{timeDisplay(
										language as string,
										new CustomDate(timeline.date).UTCDateString || '',
										pageData?.assets?.label_at
									)}
								</span>

								<div className={joinClasses([pageUtils.classes.historyText,!shouldDisplayShortMessage(timeline) ? pageUtils.classes.historyTextSpace : ''])}>
									{getTimelineTitle(timeline)}
								</div>

								{shouldDisplayShortMessage(timeline) && (
									<div className={pageUtils.classes.blocView}>
										<div
											className={joinClasses([
												pageUtils.classes.view,
												pageUtils.classes.disableClick
											])}
										>
											{timeline.activity?.description && (
												<Text
													content={getTextWithoutLineBreaks(
														timeline.activity?.description
													)}
												/>
											)}
										</div>
										{!!timeline.activity?.attachments &&
											timeline.activity?.attachments.map(
												(attachment, index) => (
													<div
														key={index}
														className={pageUtils.classes.attachment}
													>
														<img
															src={paperGreyClipIcon}
															className={pageUtils.classes.dropbtnImg}
															width="15"
														/>
														{attachment?.fileName}
													</div>
												)
											)}
										<div
											onClick={() => {
												setModalCommunication(timeline)
												setOrigin(timeline.from as ORIGIN)
												onOpenModal(true)
											}}
										>
											<a className={pageUtils.classes.link}>
												{pageData?.assets?.label_show_more}
											</a>
										</div>
									</div>
								)}
							</div>
						</div>
					))}
		</>
	)
}

export default TimelineBlock
